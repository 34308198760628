import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#bf8633",
        button: "#d2a749"
      },
      dark: {
        primary: "#000000",
        secondary: "#bf8633",
        button: "#d2a749"
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
