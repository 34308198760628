import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueColumnsResizable from './plugins/vue-columns-resizable';
import './assets/global.css';

Vue.use(Chartkick.use(Chart));
Vue.use(VueColumnsResizable);
Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.prototype.$globals = { 
  //endpoint: "https://localhost:44385/",
  //endpoint: "https://queroir.com.br/service/",
  //endpoint: "https://192.168.0.106:8081/",
  endpoint: "https://www.fabiomerli.co.uk/service/",
  voucherCategoriaTroca: "aee941d9-926a-40a4-bc4e-506fc9476c86",
  voucherCategoriaPromocao: "6c4fe3bd-1470-452e-84a0-e2d74067bb54",
  voucherCategoriaAprovacao: "354fd8a9-eae6-4fe8-a0aa-bb3fa16d175d",
  voucherCategoriaIndicacao: "a10e034e-2291-44c8-80ef-eccf3a567863",
  clienteStatusPendente: "63dcbe44-e538-4580-8130-901f8cc282b3",
  clienteStatusAprovado: "934cc63a-2967-4b11-b424-705818ccf94a",
  clienteStatusReprovado: "3e9fb455-1bda-4c02-b398-d9607fab836b",
  headerPadrao: { 
    'Usuario': JSON.parse(localStorage.getItem("login"))?.id,
    'Content-Type': 'application/json' 
  },
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
