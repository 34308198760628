import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/cliente/login.vue')
  },
  {
    path: '/home',
    name: 'homeCliente',
    component: () => import('../views/cliente/home.vue')
  },
  {
    path: '/admin/login',
    name: 'admin',
    component: () => import('../views/admin/login.vue')
  },
  {
    path: '/admin/home',
    name: 'home',
    component: () => import('../views/admin/home.vue')
  },
  {
    path: '/admin/aprovacaoclientes',
    name: 'aprovacaoclientes',
    component: () => import('../views/admin/aprovacao-cliente.vue')
  },
  {
    path: '/admin/cadastroclientes',
    name: 'clientes',
    component: () => import('../views/admin/cliente.vue')
  },
  {
    path: '/admin/politicasprivacidade',
    name: 'politicasprivacidade',
    component: () => import('../views/admin/politica-privacidade.vue')
  },
  {
    path: '/admin/servicos',
    name: 'servicos',
    component: () => import('../views/admin/servico.vue')
  },
  {
    path: '/admin/banners',
    name: 'banners',
    component: () => import('../views/admin/banner.vue')
  },
  {
    path: '/admin/validarcupom',
    name: 'validarcupom',
    component: () => import('../views/admin/validar-cupom.vue')
  },
  {
    path: '/admin/voucher',
    name: 'voucher',
    component: () => import('../views/admin/emissao-cupom.vue')
  },
  {
    path: '/admin/usuarios',
    name: 'usuario',
    component: () => import('../views/admin/usuario.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'ignore'
})

export default router
