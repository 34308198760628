<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created: function () {
    const documentHeight = () => {
    const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight - 1}px`);
    }
    window.addEventListener('resize', documentHeight);
    documentHeight();
  },
};
</script>

<style>
:root {
 --doc-height: 100%;
}

html,
body {
 padding: 0;
 margin: 0;
 height: 100vh; /* fallback for Js load */
 height: var(--doc-height);
}

@media (max-width: 600px){
  .v-dialog{
    margin: 6px !important;
  }
  .v-dialog--fullscreen{
    margin: 0px !important;
  }
}
</style>